var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row newspaper"
  }, [_c('div', {
    staticClass: "col-md-12 mt-2"
  }, [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": _vm.statusOptions
    },
    on: {
      "input": _vm.statusChange
    },
    model: {
      value: _vm.status,
      callback: function ($$v) {
        _vm.status = $$v;
      },
      expression: "status"
    }
  })], 1), _c('b-col', [_c('b-button', {
    on: {
      "click": _vm.onClearSearch
    }
  }, [_vm._v("Clear")])], 1)], 1), _c('b-row', {
    staticClass: "py-2"
  }, [_c('b-col', {
    attrs: {
      "lg": "5",
      "md": "6"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.query_search,
      expression: "query_search"
    }],
    staticClass: "form-control",
    attrs: {
      "placeholder": "Type Here to Search Newspaper",
      "type": "search"
    },
    domProps: {
      "value": _vm.query_search
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) { return; }
        _vm.query_search = $event.target.value;
      }, _vm.onSearch],
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.onSearch.apply(null, arguments);
      }
    }
  }), _vm.typing ? _c('small', [_c('em', [_vm._v(" typing ... ")])]) : _vm._e()]), _c('b-col', {
    staticClass: "d-flex justify-content-end pt-1"
  }, [_c('span', {
    staticClass: "pr-2"
  }, [_c('router-link', {
    staticClass: "btn btn-sm btn-dark",
    attrs: {
      "to": {
        name: _vm.NewspaperCreatePath.name
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-plus"
  })])], 1), _c('span', [_c('button', {
    staticClass: "btn btn-sm btn-dark",
    on: {
      "click": _vm.reloadPage
    }
  }, [_c('i', {
    staticClass: "fa fa-refresh"
  })])]), _c('span', [_c('label', {
    staticClass: "col-sm-auto",
    attrs: {
      "for": ""
    }
  }, [_vm._v("Count ")])]), _c('span', [_c('strong', {
    staticClass: "col-sm-auto"
  }, [_vm._v(_vm._s(_vm.formatPriceBasic(_vm.totalRows)))])])])], 1), _c('TableBooks', {
    attrs: {
      "books": _vm.Newspaper,
      "currentPage": _vm.currentPage,
      "perPage": _vm.perPage,
      "isLoading": _vm.isLoading,
      "reloadPage": _vm.reloadPage
    }
  })], 1), _c('b-row', {
    staticClass: "justify-content-right ml-1"
  }, [_c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('b-pagination', {
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "aria-controls": "my-table"
    },
    on: {
      "change": _vm.onClickPage
    },
    model: {
      value: _vm.currentPage,
      callback: function ($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }